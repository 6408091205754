export const CONSTANTS = {
    CLEAR_ON_SIGNOUT: "CLEAR_ON_SIGNOUT",
    SET_AUTH_CREDENTIALS: "SET_AUTH_CREDENTIALS",
    SET_BANNER: "SET_BANNER",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    SET_WHY: "SET_WHY",
    SET_MODAL: "SET_MODAL",
    SET_USER: "SET_USER",
    SET_CHAIR_MODAL: "SET_CHAIR_MODAL",
    SET_CHAIR_CREDENTIALS: "SET_CHAIR_CREDENTIALS",
    CHAIR_OUT: "CHAIR_OUT",
    SET_PAGES: "SET_PAGES",
}
